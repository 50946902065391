import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import {RebirthHttpModule} from "@otr/rebirth-http";
import {CommonModule} from "@angular/common";
import { RouteReuseStrategy } from '@angular/router';
import {ConfigParams, RouteCacheService, WebsiteCommonModule, OTRRouteReuseStrategy, Store, PasswordResetService} from "@otr/website-common";
import { environment } from '../environments/environment';
import {TabConfigService} from "./tab-config";

const commonModuleConfig: ConfigParams = {
  environment,
  tabConfigService: TabConfigService,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    InlineSVGModule,
    RebirthHttpModule,
    WebsiteCommonModule.forRoot(commonModuleConfig)
  ],
  providers: [
    RouteCacheService,
    PasswordResetService,
    {
      provide: RouteReuseStrategy,
      useClass: OTRRouteReuseStrategy,
    },
    Store,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
