import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  InitializeComponent, NoContentComponent,
  PasswordResetComponent,
  RoleSelectComponent,
  SingleSignOnComponent
} from "@otr/website-common";

const routes: Routes = [
  {
    path: '',
    component: SingleSignOnComponent,
  },
  {
    path: 'role-select',
    component: RoleSelectComponent,
  },
  {
    path: 'initialize',
    component: InitializeComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: '**',
    component: NoContentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
