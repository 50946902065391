import * as _ from 'lodash';
import { getVehicleBusinessType, VehicleBusinessType } from '@otr/website-common';

export const vanFeatureToggle = (dealerIntegrationToggles: any) =>
  !_.isNil(_.find(dealerIntegrationToggles, { name: 'VAN_OTR_PLUS', enabled: true }));

export function isVanEnabled(dealerIntegrationToggles: any, tokenService: any) {
  return  vanFeatureToggle(dealerIntegrationToggles) && tokenService.isVanAuthorised();
}

export function isVanForSameDealer() {
  return getVehicleBusinessType() === VehicleBusinessType.VAN.value;
}
